<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Tickets
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <!-- <button
        @click="addNote"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Add Comment</span>
      </button> -->
    </div>
    <div class="flex">
      <vue-good-table
        v-if="tickets.length > 0"
        class="w-full"
        styleClass="vgt-table condensed"
        mode="remote"
        :rows="tickets"
        :columns="columns"
        :totalRows="totalTickets"
        :row-style-class="markRowAsSelected"
        @on-row-click="openServiceTicket"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :pagination-options="{
          enabled: true,
          perPage: 50,
          perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
          dropdownAllowAll: false,
        }"
      />
      <p
        class="mx-auto my-16"
        v-if="viewState == 'Idle' && (!tickets || tickets.length == 0)"
      >
        There are no Tickets on this Contact.
      </p>
      <div
        v-if="viewState == 'Loading'"
        class="flex w-full justify-center py-20"
      >
        <Spinner :color="'text-black'" :size="10" />
      </div>
    </div>
  </div>
</template>

<script>
import AddEditNote from "@/components/SalesProspects/Edit/AddEditNote.vue";
import ConfirmAlert from "@/components/ConfirmAlert.vue";
import Panel from "@/components/Panel.vue";
import Spinner from "@/components/Spinner.vue";
import Notification from "@/components/Notification.vue";

export default {
  name: "Tickets",
  props: {
    contactId: {
      type: String,
      required: true,
    },
  },
  components: {
    AddEditNote,
    ConfirmAlert,
    Panel,
    Spinner,
    Notification,
  },
  data() {
    return {
      tickets: [],
      viewState: "Idle",
      totalTickets: 0,
      totalPages: 0,
      currentPage: 0,
      pagingParams: {
        page: 1,
        limit: 50,
      },
      columns: [
        {
          label: "TICKET #",
          field: "ticket_number",
          width: "150px",
          tdClass: this.markTableData,
        },
        {
          label: "Severity",
          field: "severity",
          width: "150px",
          tdClass: this.markTableData,
        },
        {
          label: "Title",
          field: "title",
          tdClass: this.markTableData,
        },
        {
          label: "Customer",
          field: "contact_name",
          tdClass: this.markTableData,
        },
        {
          label: "Equipment",
          field: "equipment_name",
          tdClass: this.markTableData,
        },
        {
          label: "Follow Up At",
          field: "follow_up_at",
          width: "200px",
          type: "date",
          formatFn: (v) => {
            return v == 0 ? "" : this.$moment.unix(v).format("DD/MM/YYYY");
          },
          tdClass: this.markTableData,
        },
      ],
    };
  },
  async mounted() {
    await this.loadTickets();
  },
  methods: {
    loadTickets: async function() {
      this.viewState = "Loading";
      try {
        let result = await this.ServiceTicketService.getServiceTickets(
          this.pagingParams,
          this.$store.state.serviceTicketOptions.showClosed,
          this.contactId
        );
        console.log(`[Tickets] loadTickets - Results from API: `, result.data);
        this.tickets = result.data.map((tik) => {
          let strSeverity = tik.severity;
          switch (tik.severity) {
            case 1:
              strSeverity = "Low";
              break;
            case 2:
              strSeverity = "Medium";
              break;
            case 3:
              strSeverity = "High";
              break;
          }

          return {
            severity_string: strSeverity,
            ...tik,
          };
        });
        this.totalTickets = result.count;
      } catch (error) {
        // console.error(error);
        console.log(`[Tickets] loadTickets - Error from API: ${error}`);
      } finally {
        this.viewState = "Idle";
      }
    },
    markRowAsSelected: function(row) {
      return row.is_closed ? "bg-green-200 text-green-200" : "";
    },
    markTableData: function(row) {
      return row.is_closed ? "vgt-text-override" : "text-vgt-default";
      // if (row.field > 50) {
      //   return "red-class";
      // }
      // return "green-class";
    },
    async onPageChange(params) {
      this.updatePagingParams({ page: params.currentPage });
      await this.loadTickets();
    },

    async onPerPageChange(params) {
      this.updatePagingParams({ limit: params.currentPerPage });
      await this.loadTickets();
    },

    async onSortChange(params) {
      this.updatePagingParams({
        order: params[0].type,
        orderBy: params[0].field,
      });
      await this.loadTickets();
    },

    updatePagingParams(newProps) {
      console.log("updateParams", newProps);
      this.pagingParams = Object.assign({}, this.pagingParams, newProps);
    },

    onColumnFilter(params) {
      console.log("onColumnFilter", params);
    },
    openServiceTicket: function(selectedRow) {
      let ticketId = selectedRow.row.ticket_id;
      this.$router.push({
        name: "service-ticket-edit",
        params: { ticketId: ticketId },
      });
    },
  },
};
</script>
