<template>
  <form @submit.prevent="saveNote" class="flex flex-wrap">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Note</label>
      <textarea
        v-model="note.note"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Note..."
        rows="10"
      ></textarea>
    </div>

    <div class="w-full">
      <button
        type="button"
        @click="saveNote"
        class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 py-1 px-3 rounded border border-green-400 hover:border-green-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save Note</span>
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: "AddEditNote",
  props: {
    note: {
      required: true,
      type: Object
    }
  },
  methods: {
    saveNote: function() {
      this.$emit("complete", this.note);
    }
  }
};
</script>